import store from '@/store'
import rest from '@/plugins/RestService.js';

import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/adm',
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminAccess.vue'),
    children: [
      {
        path: '',
        name: 'Admin1View',
        component: () => import(/* webpackChunkName: "about" */ '../views/Admin1View.vue'),
      },
      {
        path: '/adm2',
        name: 'Admin2View',
        component: () => import(/* webpackChunkName: "about" */ '../views/Admin2View.vue'),
      },
      {
        path: '/adm3',
        name: 'Admin3View',
        component: () => import(/* webpackChunkName: "about" */ '../views/Admin3View.vue'),
      },
      {
        path: '/adm4',
        name: 'Admin4View',
        component: () => import(/* webpackChunkName: "about" */ '../views/Admin4View.vue'),
      },
    ]
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "about" */ '../views/NormalAccess.vue'),
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
      },
      {
        path: 'abt',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
      },
      {
        path: '',
        name: 'ComingSoon',
        meta: {
          noHeaderFooter: true 
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/ComingSoonView.vue')
      },
      {
        path: 'conf',
        name: 'Configurator',
        component: () => import(/* webpackChunkName: "about" */ '../views/ConfiguratorView.vue'),
        children: [
          {
            path: ':index',
            name: 'ConfiguratorDetailView', 
            component: () => import(/* webpackChunkName: "alarme" */ '../views/ConfiguratorDetailView.vue')
          },
        ]
      },
      {
        path: 'cnt',
        name: 'Contact',
        component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue')
      },
      {
        path: 'glr',
        name: 'Gallery',
        component: () => import(/* webpackChunkName: "about" */ '../views/GalleryView.vue')
      },
      {
        path: 'glr1',
        name: 'Gallery1',
        component: () => import(/* webpackChunkName: "about" */ '../views/Gallery1View.vue'),
        children: [
          {
            path: 'horsol/:index',
            name: 'HorsolDetail',
            component: () => import(/* webpackChunkName: "horsol" */ '../views/AboveGroundDetailView.vue')
          },
        ]
      },
      {
        path: 'glr2',
        name: 'Gallery2',
        component: () => import(/* webpackChunkName: "about" */ '../views/Gallery2View.vue'),
        children: [
          {
            path: 'imerg/:index',
            name: 'ImergDetail',
            component: () => import(/* webpackChunkName: "imerg" */ '../views/ImmergedDetailView.vue')
          }
        ]
      },
      {
        path: 'glr3',
        name: 'Gallery3',
        component: () => import(/* webpackChunkName: "about" */ '../views/Gallery3View.vue'),
        children: [
          {
            path: 'alarme/:index',
            name: 'AlarmeDetail',
            component: () => import(/* webpackChunkName: "alarme" */ '../views/AlarmDetailView.vue')
          },
        ]
      },
      // {
      //   path: 'glr4',
      //   name: 'Gallery4',
      //   component: () => import(/* webpackChunkName: "about" */ '../views/Gallery4View.vue')
      // },
      // {
      //   path: 'glr5',
      //   name: 'Gallery5',
      //   component: () => import(/* webpackChunkName: "about" */ '../views/Gallery5View.vue')
      // },
      // {
      //   path: 'glr6',
      //   name: 'Gallery6',
      //   component: () => import(/* webpackChunkName: "about" */ '../views/Gallery6View.vue')
      // },

      {
        path: 'srv',
        name: 'Services',
        component: () => import(/* webpackChunkName: "about" */ '../views/ServicesView.vue')
      },
      {
        path: 'cnx',
        name: 'Connexion',
        component: () => import(/* webpackChunkName: "about" */ '../views/ConnexionView.vue')
      },
      {
        path: 'insc',
        name: 'Inscription',
        component: () => import(/* webpackChunkName: "about" */ '../views/InscriptionView.vue')
      },
      {
        path: 'mdpo',
        name: 'MdpView',
        component: () => import(/* webpackChunkName: "about" */ '../views/MdpView.vue')
      },
      {
        path: 'confirm',
        name: 'ConfirmView',
        component: () => import(/* webpackChunkName: "about" */ '../views/ConfirmView.vue')
      },
      {
        path: 'confirmation',
        name: 'ConfirmationView',
        component: () => import(/* webpackChunkName: "about" */ '../views/ConfirmationView.vue')
      },
      {
        path: 'change',
        name: 'ChangeView',
        component: () => import(/* webpackChunkName: "about" */ '../views/ChangeView.vue')
      },
      {
        path: 'confirmation1',
        name: 'Confirmation1View',
        component: () => import(/* webpackChunkName: "about" */ '../views/Confirmation1View.vue')
      },
      {
        path: 'Validation/:id',
        redirect: to => {
          if (to.params.id !== undefined) {
            store.state.id = to.params.id
            rest.getData('Users', 'Number', 'ID', to.params.id).then((r) => {
              r[0].Status = true;
              rest.putElement('Users', r[0]);
            })
            return { name: 'ConfirmView' }
          }
        }
      },
      {
        path: 'Changement/:id',
        redirect: to => {
          if (to.params.id !== undefined) {
              store.state.id1 = to.params.id
            return { name: 'MdpView' }
          }
          else return { name: '404View' }
        }
      },
      {
        path: 'prof',
        name: 'ProfilView',
        component: () => import(/* webpackChunkName: "about" */ '../views/ProfilView.vue')
      },
      {
        path: 'err404',
        name: '404View',
        meta: {
          noHeaderFooter: true 
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/404View.vue')
      },
    ]
  },
  {
    path: '/:pathMatch(.*)',
    redirect: 'err404'
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path.startsWith('/conf') && window.innerWidth < 1200) {
    alert("Veuillez utiliser un grand écran pour réaliser votre configuration convenablement.");
    next('home'); 
  } else if (to.path === '/') {
    const redirectTo = store.state.roleu === 'Admin' ? '/adm' : '/home';
    next(redirectTo);
  } else {
    const shouldHideHeaderFooter = to.meta.noHeaderFooter === true;
    store.commit('updateHeaderFooterVisibility', !shouldHideHeaderFooter);
    next(); 
  }
});


export default router
