import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCN9zd1ds1xnhjbhQBoeMvFknCtVq18x6o",
  authDomain: "solutionpiscine.firebaseapp.com",
  projectId: "solutionpiscine",
  storageBucket: "solutionpiscine.appspot.com",
  messagingSenderId: "431338563339",
  appId: "1:431338563339:web:eeae56a26367155faba40e"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Storage
const storage = getStorage(firebaseApp);

export { storage };
