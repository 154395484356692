import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import store from './store';
import router from './router';
import Vue3Signature from "vue3-signature";
import './Firebase';

loadFonts();

createApp(App).use(router).use(store)
  .use(vuetify).use(Vue3Signature)
  .mount('#app');


