import axios from 'axios'
const instance = axios.create()
instance.defaults.headers.common.apikey = 'QUJERUxLQURFUi5BWlpBWi0xNS8wNy8xOTc0X1RsZW1jZW4xMzAw'
// instance.defaults.baseURL = `${process.env.RESTAPI_URI}`
async function signin (login, password) {
  let value = null
  const content = [{ Type: 'String', Key: 'NDC', Operator: '=', Value: login }, { Type: 'String', Key: 'MDP', Operator: '=', Value: password }]
  const uri = '/get/elements/POOL01/Users'
  try {
    await instance.post(uri, content).then(response => { value = response.data })
  } catch (error) {
    console.log(error)
  }
  return value
}

//  async function index(table) {

//      let value = -1
//      try {
//          const uri = `${global.RESTAPI_URI}/index/${table}`
//          console.log(uri)
//          response = await axios.get(uri)
//          value = response.data[0]
//      } catch (error) {
//      }
//      return value
//  }
async function getElements (table) {
  let value = null
  let response = null
  const uri = '/get/elements/POOL01/' + table
  try {
    response = await instance.post(uri)
    // const uri = `${global.RESTAPI_URI}/table/${table}/${limit}/${offset}`
    value = response.data
  } catch (error) {
    if (error.response) {
      // la requête a été faite et le code de réponse du serveur n’est pas dans
      // la plage 2xx
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
    } else if (error.request) {
      // la requête a été faite mais aucune réponse n’a été reçue
      // `error.request` est une instance de XMLHttpRequest dans le navigateur
      // et une instance de http.ClientRequest avec node.js
      console.log(error.request)
    } else {
      // quelque chose s’est passé lors de la construction de la requête et cela
      // a provoqué une erreur
      console.log('Error', error.message)
    }
    console.log(error.config)
  }
  return value
}

async function postElement (table, data) {
  let value = null
  let response = null
  try {
    response = await instance.post('/post/element/POOL01/' + table, data)
    value = response.data
  } catch (error) {
    console.log(error);
  }
  return value
}

async function putElement (table, data) {
  let value = null
  let response = null
  try {
    response = await instance.put('/element/POOL01/' + table, data)
    value = response.data
  } catch (error) {
    console.log(error);
  }
  return value
}

async function deleteElement (table, id) {
  let value = null
  let response = null
  try {
    response = await instance.delete('/element/POOL01/' + table + '/' + id)
    value = response.data
  } catch (error) {
    console.log(error);
  }
  return value
}
async function getData (table, type, condition, conditionValue) { // wanted, condition, conditionValue
  let value = null
  let response = null
  try {
    const content = [{ Type: type, Key: condition, Operator: '=', Value: conditionValue }]
    response = await instance.post('/get/elements/POOL01/' + table, content)
    value = response.data
  } catch (error) {
    console.log(error);
  }
  return value
}

export default {
  signin,
  getElements,
  postElement,
  putElement,
  deleteElement,
  getData,
}
