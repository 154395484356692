import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import rest from '@/plugins/RestService.js';

export default createStore({
  plugins: [createPersistedState()],
  state: {
    user: null,
    headerfooter: false,
    mail: '',
    id: null,
    mail1: '',
    id1: null,
    ndcu: '',
    mdpu: '',
    mailu: '',
    phoneu: '',
    adressu: '',
    roleu: '',
    valu: false,
    poolconfig: {
      PoolState: null,
      PoolColor: null,
      PoolBox: null,
      PoolCommand: null,
      PoolSlats: null,
      PoolSlatsColor: null,
      PoolFinition: null,
      PoolFastners: null,
      PoolFixation: null,
      PoolFixationColor: null,
      PoolFastnersColor: null,
      pdfURL: null,
      PoolShapes: null,
      PoolShape: null,
      selectedShapeIndex1: null,
      selectedShapeIndex2: null,
      selectedShapeIndex3: null,
      selectedShapeIndex4: null,
      selectedShapeIndex5: null,
      selectedcolor1Index: null,
      selectedcolor2Index: null,
      selectedcolor3Index: null,
      selectedcolor4Index: null,
      selectedcolor5Index: null,
      selectedcolor6Index: null,
      selectedcolor7Index: null,
      selectedcolor8Index: null,
      selectedcolor9Index: null,
      selectedcolor10Index: null,
      selectedcolor11Index: null,
      selectedfinitionIndex: null,
      PoolMaterial: null,
      PoolMaterialDetail: null,
      PoolMaker: null,
      PoolModel: null,
      PoolCoating: null,
      PoolCoatingDetail: null,
      PoolMeasures: null,
      PoolThickness: null,
      d1: null,
      d2: null,
      d3: null,
      d4: null,
      d5: null,
      d6: null,
      d7: null,
      d8: null,
      PoolDimensions: null,
      Obstacles: {
        Echelle: false,
        BlocFiltrant: false,
        Buserefoulement: false,
        Nagecontrecourant: false,
        Autre: false,
      },
      OtherObstacles: null,
      PoolInfos: null,
      PoolFiles: null,
      PoolAccessories: null,
      PoolCoverType: null,
      PoolCoverColor: null,
    }
  },
  getters: {
    isHeaderFooterVisible(state) {
      return state.headerFooter
    },
    isAuthentified: (state) => (table) => {
      if (state.user === null || state.roleu === null) {
        return false
      } else {
        if (state.roleu === 'Admin')
          return true
        if (state.roleu === 'Client') {
          switch (table) {
            case '/adm':
              return false
            case '/adm2':
              return false
            case '/adm3':
              return false
            case '/adm4':
              return false
            default:
              return true
          }
        }

      }
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user.ID
      state.mailu = user.Email
      state.ndcu = user.NDC
      state.mdpu = user.MDP
      state.roleu = user.Role
      state.valu = user.Validation
      state.phoneu = user.Phone
      state.adressu = user.Adress
    },
    deco(state, user) {
      state.user = user
      state.mailu = user
      state.ndcu = user
      state.mdpu = user
      state.roleu = user
      state.valu = user
      state.phoneu = user
      state.adressu = user
    },
    updateHeaderFooterVisibility(state, value) {
      state.headerFooter = value
    }
  },
  actions: {
    async connexion({ commit }, user) {
      let data = null
      await rest.signin(user.NDC, user.MDP).then(res => { data = res[0] })
      await commit('setUser', data)
    },
    async deconnexion({ commit }) {
      await commit('deco', null)
    },
    toggleHeaderFooterVisibility({ commit }, value) {
      commit('updateHeaderFooterVisibility', value)
    }
  },
  modules: {
  }
})
